import React from "react";
// import LandingPage from "../../pages/LandingPage";
import "./App.css";
import LoginPage from "../../pages/LoginPage";

const App = () => (
  <div className="LandingPage">
    <LoginPage />
    {/* <LandingPage />  */}
  </div>
);

export default App;
