export const namedOrganisations = () =>{
    const organisation = [
        {name: "Start-up",id: 1, value:"Startup"},
        {name: "Company", id: 2, value:"Company"},
        {name: "University Project", id: 3, value: "University-Project"},
        {name: "Final Year Project", id: 4, value: "Final-Year-Project"},
        {name: "Individual", id: 6, value: "Individual"},
    ];
    return organisation;
};
